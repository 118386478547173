var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('PageHeader',{attrs:{"title":_vm.headercontent.title,"addlink":_vm.headercontent.addlink}})],1),(!_vm.pageLoading)?_c('v-col',{attrs:{"md":"12"}},[_c('h3',{staticClass:"filtertitle"},[_vm._v("Filter "+_vm._s(_vm.headercontent.title))]),_c('v-form',{ref:"form",staticClass:"form-section",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{staticClass:"mr-btm-minus",attrs:{"outlined":"","label":"Title"},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{attrs:{"items":_vm.allVideoCategory.results.list,"item-value":"categoryHandle","item-text":"categoryHandle","label":"Category","outlined":""},model:{value:(_vm.selectedCategory),callback:function ($$v) {_vm.selectedCategory=$$v},expression:"selectedCategory"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{attrs:{"items":[
                  {
                    text: 'Active',
                    value: true,
                  },
                  {
                    text: 'Inactive',
                    value: false,
                  },
                ],"item-text":"text","item-value":"value","label":"Status","outlined":""},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1),_c('v-col',{staticClass:"submitbtn",attrs:{"cols":"3"}},[_c('v-btn',{staticClass:"submitbutton btn-dark",on:{"click":_vm.filterAction}},[_vm._v(" Filter ")])],1)],1)],1)],1):_vm._e(),_c('v-col',{attrs:{"md":"12"}},[_c('v-simple-table',{staticClass:"table",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("S/N")]),_c('th',{staticClass:"text-left"},[_vm._v("Title")]),_c('th',[_vm._v("Category")]),_c('th',[_vm._v("Status")]),_c('th',{staticClass:"text-right"},[_vm._v("Action")])])]),(!_vm.pageLoading)?_c('tbody',_vm._l((_vm.allVideoSubCategory.results.list),function(item,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',[_vm._v(_vm._s(item.title))]),_c('td',[_vm._v(_vm._s(item.categoryHandle))]),_c('td',[_c('span',{staticClass:"status",style:({
                      'background-color':
                        item.status === true ? 'green' : 'red',
                    })},[_vm._v(_vm._s(item.status === true ? "Active" : "Inactive"))])]),_c('td',{staticClass:"text-right"},[_c('router-link',{attrs:{"to":`/dashboard/videoSubcategory/add/${item.slug}`}},[_c('v-icon',{staticClass:"icons-action"},[_vm._v(" mdi-pencil ")])],1),_c('v-icon',{staticClass:"icons-action",on:{"click":() => _vm.deleteSubcategoryAct(item._id)}},[_vm._v(" mdi-delete ")])],1)])}),0):_vm._e()]},proxy:true}])})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }